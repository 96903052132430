import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import { Chart, Content } from './DonutChart.styles'

const DonutChart = ({ data, children, center }) => {
  const [key, setKey] = useState(0)
  const chartEl = useRef(null)
  const drawn = useRef(false)

  const outerRadius = 320 / 2
  const thickness = 14
  const innerRadius = outerRadius - thickness
  const padAngle = 10 / (outerRadius - thickness / 2)

  const calculateAdjustedData = (data) => {
    const minAngle = (1 / outerRadius) * (Math.PI * 2)
    const totalValue = data.reduce((acc, d) => acc + d.value * d.price, 0)

    return data.map((d) => {
      const rawAngle = ((d.value * d.price) / totalValue) * (Math.PI * 2)
      return {
        ...d,
        adjustedValue:
          rawAngle < minAngle
            ? (minAngle / (Math.PI * 2)) * totalValue
            : d.value * d.price,
      }
    })
  }

  const createPieChart = (adjustedData) => {
    if (!chartEl.current) return

    const customColors = data.map((item) => item.color)
    const color = customColors[0]
      ? d3.scaleOrdinal(customColors)
      : d3.scaleOrdinal(d3.schemeCategory10)

    const svg = d3
      .select(chartEl.current)
      .append('svg')
      .attr('viewBox', `0 0 320 320`)

    const chart = svg
      .append('g')
      .attr('transform', `translate(${outerRadius}, ${outerRadius})`)

    const arc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius)
    const pie = d3
      .pie()
      .value((d) => d.adjustedValue)
      .sort(null)
      .padAngle(padAngle)

    function arcTween(a) {
      const interp = d3.interpolate(this._current, a)
      this._current = interp(1)
      return (time) => arc(interp(time))
    }

    function setD(d) {
      this._current = d
    }

    const drawDate = (dataSet) => {
      const path = chart.selectAll('path').data(pie(dataSet))

      path
        .transition()
        .ease(d3.easeQuadOut)
        .duration(1000)
        .attrTween('d', arcTween)

      path
        .enter()
        .append('path')
        .attr('fill', (d, i) => color(i))
        .attr('d', arc)
        .each(setD)
    }

    drawDate(adjustedData.map((datum) => ({ ...datum, adjustedValue: 0 })))
    return drawDate
  }

  const drawChart = () => {
    const adjustedData = calculateAdjustedData(data)
    const drawDate = createPieChart(adjustedData)
    drawDate(adjustedData)
    drawn.current = true
  }

  const onScroll = () => {
    if (drawn.current || !chartEl.current) return

    if (chartEl.current.getBoundingClientRect().top < window.innerHeight) {
      drawChart()
    }
  }

  useEffect(() => {
    const handleScroll = () => onScroll()
    window.addEventListener('scroll', handleScroll)
    onScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [data])

  return (
    <Chart ref={chartEl} center={center} key={key}>
      <Content>{children}</Content>
    </Chart>
  )
}

DonutChart.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  center: PropTypes.bool,
}

DonutChart.defaultProps = {
  children: null,
  center: false,
}

export default DonutChart
