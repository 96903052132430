import React, { useEffect, useState, useContext, useMemo, useRef } from 'react'
import {
  Button,
  Column,
  Row,
  ComponentWrapper,
  SectionTitle,
  AuthContext,
  Pagination,
  Text,
  //TransactionDetails,
} from '@stokr/components-library'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import { Container } from 'components/Transactions/Transactions.styles'
import TransactionInfo from 'components/TransactionInfo/TransactionInfo'
import moment from 'moment'
import { TransactionTypes } from 'constants/enums'
import { InputWithButton } from '@stokr/components-library/dist/components/Input/InputWithButton'
import {
  Card,
  CardTitle,
} from '@stokr/components-library/dist/components/Card/Card.styles'
import { formatCurrencyValue } from '@stokr/components-library/dist/utils/formatCurrencyValue'
import TransactionDetails from 'components/TransactionDetails/TransactionDetails'

const Transactions = () => {
  const { user } = useContext(AuthContext)
  const { wallets = [] } = user

  const [isLoading, setIsLoading] = useState(true)
  const [noInvestments, setNoInvestments] = useState(false)

  const [finalizedTransactions, setFinalizedTransactions] = useState([])
  const [pendingTransactions, setPendingTransactions] = useState([])

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [inputPageSize, setinputPageSize] = useState(pageSize.toString())
  const finalizedTxScroll = useRef(null)

  const currentFinalizedTx = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return finalizedTransactions.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, finalizedTransactions, pageSize])

  const { finalizedTx, pendingTx, tokenTransfers } = useContext(EventDBContext)

  useEffect(() => {
    if (wallets.length === 0) {
      setNoInvestments(true)
      setIsLoading(false)
    }
  }, [wallets])

  useEffect(() => {
    if (!tokenTransfers) {
      return
    }

    if (finalizedTx.length > 0 || pendingTx.length > 0) {
      const sortedPending = sortByDate(pendingTx)
      const sortedFinalized = sortByDate(finalizedTx)

      // const withAddressNameFinalized = addAddressName(sortedFinalized, wallets)
      //const withAddressNamePending = addAddressName(sortedPending, wallets)

      const withTransactionInfoFinalized = addTransactionInfo(
        sortedFinalized,
        wallets,
      )
      const withTransactionsInfoPending = addTransactionInfo(
        sortedPending,
        wallets,
      )

      setFinalizedTransactions(withTransactionInfoFinalized)
      setPendingTransactions(withTransactionsInfoPending)

      // In case there are only pending euro tx older than 7 days
      setNoInvestments(
        withTransactionInfoFinalized.length === 0 &&
          withTransactionsInfoPending.length === 0,
      )
    } else {
      setNoInvestments(true)
    }

    setIsLoading(false)
  }, [tokenTransfers, finalizedTx, pendingTx])

  function renderPendingTable() {
    return pendingTransactions.length > 0 ? (
      <>
        <ComponentWrapper noPaddingHorizontal noPaddingTop>
          <SectionTitle style={{ fontWeight: 700 }}>
            Pending transactions
          </SectionTitle>
        </ComponentWrapper>
        {createTransactionList(pendingTransactions)}
      </>
    ) : (
      ''
    )
  }

  function renderFinalizedTable() {
    return finalizedTransactions.length > 0 ? (
      <>
        <ComponentWrapper
          noPaddingHorizontal
          noPaddingTop
          ref={finalizedTxScroll}
        >
          <SectionTitle style={{ fontWeight: 700 }}>
            Finalised transactions
          </SectionTitle>
        </ComponentWrapper>
        {createTransactionList(currentFinalizedTx)}
        <ComponentWrapper
          noPaddingHorizontal
          flex
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Pagination
            currentPage={currentPage}
            totalCount={finalizedTransactions.length}
            pageSize={pageSize}
            onPageChange={(page) => {
              setCurrentPage(page)

              //scroll into view with vertical offset (to avoid fixed header overlap)
              const yOffset = -100
              const element = finalizedTxScroll.current
              if (element) {
                const y =
                  element?.getBoundingClientRect().top +
                  window.pageYOffset +
                  yOffset

                window.scrollTo({ top: y, behavior: 'smooth' })
              }
            }}
          />
          <Card noPadding>
            <CardTitle style={{ padding: '2px 20px' }}>Page Size:</CardTitle>
            <InputWithButton
              id="pageSize"
              name="pageSize"
              value={inputPageSize}
              type="number"
              placeholder={'Page size'}
              onChange={(e) => setinputPageSize(Number(e.target.value))}
            >
              <Button onClick={() => setPageSize(Number(inputPageSize))}>
                apply
              </Button>
            </InputWithButton>
          </Card>
        </ComponentWrapper>
      </>
    ) : (
      ''
    )
  }

  return (
    <Container>
      {isLoading ? (
        <ComponentWrapper center fluidcenter>
          <Text blockchainLoadingMsg>
            <h4>
              A few seconds left. We are still reading the data out of the
              blockchain...
            </h4>
          </Text>
        </ComponentWrapper>
      ) : noInvestments ? (
        <ComponentWrapper style={{ width: '100%' }}>
          <Text>
            <h4>
              You have not made any transactions yet. You want to see what you
              can invest in? Please discover Investment Opportunities.
            </h4>
          </Text>
          <Button
            style={{ marginTop: '3rem' }}
            onClick={() =>
              window.location.replace(
                'https://stokr-staging.de/featured-assets',
              )
            }
          >
            INVESTMENT OPPORTUNITIES
          </Button>
        </ComponentWrapper>
      ) : (
        <Row center>
          <Column part={8}>
            {renderPendingTable()}
            {renderFinalizedTable()}
          </Column>
        </Row>
      )}
    </Container>
  )
}

function sortByDate(purchases) {
  // Group by day of purchase
  const groupedByDay = []

  purchases.forEach((purchase) => {
    const transferDate = new Date(purchase.createdAt || purchase.time)
    const day = String(transferDate.getDate()).padStart(2, '0')
    const month = String(transferDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const year = transferDate.getFullYear()
    const date = `${year}-${month}-${day}`

    if (groupedByDay[date]) {
      groupedByDay[date].push(purchase)
    } else {
      groupedByDay[date] = [purchase]
    }
  })

  // Create array of arrays where [0] is day and [1] is purchases
  const sortedByDay = Object.entries(groupedByDay).reduce(
    (accumulator, date) => [...accumulator, [date[0], date[1]]],
    [],
  )

  // Sort descending
  sortedByDay.sort((a, b) => {
    return new Date(b[0]) - new Date(a[0])
  })

  return sortedByDay
}

const addTransactionInfo = (purchasesByDate, investorWallets) => {
  return purchasesByDate.map((byDate) => {
    const date = byDate[0]
    const purchases = byDate[1]
    purchases.forEach((purchase) => {
      const {
        currencyAmount,
        currencyType,
        secondaryCurrency,
        secondaryCurrencyinEURorUSD,
        tokenAmount,
        tokenSymbol,
        amount,
        recipient,
        isRedemption,
        sender,
        type,
        txid,
      } = purchase

      //Transactions appear in the following order:
      //on the left what is received/in, on the right what is sent/out.
      let txInfo = {
        value2: secondaryCurrency
          ? currencyAmount / secondaryCurrencyinEURorUSD
          : currencyAmount ?? 0,
        unit2: currencyType,
        value1: amount ? amount : tokenAmount ? Math.abs(tokenAmount) : 0,
        unit1: tokenSymbol,
        //name1:investorAddressName
        //owner1:"(you)"
        // name2:tokenName
      }

      let hasReceived = investorWallets.find(
        (wallet) => wallet.address === recipient,
      )
      let hasSent = investorWallets.find((wallet) => wallet.address === sender)

      if (hasReceived && hasSent) {
        purchase.isInternal = true
      }

      //secondary market tx
      if (type === TransactionTypes.TRANSFER) {
        txInfo.value1 = hasReceived ? amount : '-'
        txInfo.unit1 = hasReceived ? tokenSymbol : ''
        txInfo.value2 = hasSent ? amount : '-'
        txInfo.unit2 = hasSent ? tokenSymbol : ''

        //two fields for tx icon
        txInfo.received = hasReceived ? true : false
        txInfo.sent = hasSent ? true : false
      }

      if (type === TransactionTypes.RECOVERY) {
        txInfo.value1 = amount
        txInfo.value2 = amount
        txInfo.unit2 = tokenSymbol
      }

      if (txid) {
        purchase.blockhainLink = 'https://blockstream.info/liquid/tx/'
      }

      //for redemptions, we need to swap the values
      if (type === TransactionTypes.REDEMPTION || isRedemption) {
        let tempValue = txInfo.value1
        let tempUnit = txInfo.unit1
        txInfo.value1 = txInfo.value2
        txInfo.unit1 = txInfo.unit2
        txInfo.value2 = tempValue
        txInfo.unit2 = tempUnit
        purchase.isRedemption = true
      }

      purchase.txInfo = txInfo
    })

    return [date, purchases]
  })
}

function createTransactionList(purchases) {
  return purchases.map((byDate) => {
    const purchases = byDate[1]
    const date = byDate[0]

    return (
      <div key={date}>
        <ComponentWrapper noPaddingHorizontal noPaddingTop>
          <SectionTitle>{moment(date).format('DD MMM YYYY')}</SectionTitle>
        </ComponentWrapper>
        {purchases.reverse().map((purchase) => {
          const { txHash, _id, files, txInfo, tokenDecimals } = purchase

          return (
            <ComponentWrapper noPaddingHorizontal noPaddingTop key={txHash}>
              <TransactionInfo
                value1={txInfo.value1}
                unit1={txInfo.unit1}
                value2={txInfo.value2}
                received={txInfo.received}
                sent={txInfo.sent}
                unit2={txInfo.unit2}
                name1={txInfo.name1}
                name2={txInfo.name2}
                owner1={txInfo.owner1}
                tokenDecimals={tokenDecimals}
              />
              {
                <TransactionDetails
                  data={getTransactionTableData(purchase)}
                  investmentId={_id}
                  files={files}
                />
              }
            </ComponentWrapper>
          )
        })}
      </div>
    )
  })
}

const getTransactionTableData = (transaction) => {
  let data = []
  const {
    currencyAmount,
    currencyType,
    secondaryCurrency,
    secondaryCurrencyinEURorUSD,
    tokenAmount,
    createdAt,
    tokenUID,
    isPrivateSale,
    blockhainLink,
    issuanceType,
    isRedemption,
    fulfilled,
    time,
    type,
    txInfo,
    txid,
    paidOut,
    paidAt,
    isInternal,
    paymentTxHash,
    txHash,
    tokenDecimals,
    projectName,
  } = transaction

  data = [
    {
      label: isRedemption ? 'REDEMPTION TIMESTAMP' : 'Timestamp',
      value: moment(createdAt || time).format('DD MMM YYYY, HH:mm:ss'),
    },

    {
      label: 'Price per token',
      value: currencyType
        ? isRedemption && currencyType === 'lbtc'
          ? (currencyAmount / tokenAmount).toFixed(6)
          : formatCurrencyValue(
              currencyType,
              (secondaryCurrency
                ? currencyAmount / secondaryCurrencyinEURorUSD
                : currencyAmount) / tokenAmount,
              isRedemption ? 6 : 2,
            )
        : 'PRICE UNKOWN',
    },
    {
      label: 'Transaction',
      value:
        projectName === 'techforgood'
          ? 'Donation' //depends on project name, should be avoided
          : isInternal
          ? 'Internal transfer'
          : txInfo.received
          ? 'Received'
          : txInfo.sent
          ? 'Sent'
          : type
          ? type
          : isRedemption
          ? 'Redemption'
          : isPrivateSale
          ? 'Private Sale'
          : 'Public Sale',
    },
    {
      label:
        txHash && isRedemption
          ? 'REDEMPTION TRANSACTION ID'
          : txHash
          ? 'LIQUID TRANSACTION ID'
          : issuanceType === 'liquid'
          ? 'LIQUID ASSET ID'
          : '',
      value: txHash ? `${txHash}` : `${tokenUID}`,
      isLink: true,
      link: blockhainLink,
    },
  ]

  if (isRedemption && paidOut) {
    data.splice(1, 0, {
      label: 'Payout Timestamp',
      value: moment(paidAt).format('DD MMMM YYYY, HH:mm:ss'),
    })
    data.splice(data.length, 0, {
      label: 'PAYOUT TRANSACTION ID',
      value: paymentTxHash,
      isLink: true,
      link: blockhainLink,
    })
  }

  return data
}

export default Transactions
